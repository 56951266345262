import tw, { styled } from 'twin.macro';

const StyledCMSPage = styled.article`
  ${tw`mx-auto mt-10 px-2 md:px-16`};

  h1 {
    ${tw`text-2xl md:text-4xl antialiased`};
  }

  h2 {
    ${tw`text-xl md:text-2xl mt-5 mb-3 font-thin antialiased`};
  }

  p {
    ${tw`mb-3`};
  }

  .features {
    ${tw`mt-16`};

    .feature {
      ${tw`flex flex-row my-10 p-10 rounded-2xl`};

      .feature_content {
        ${tw`flex flex-col items-start ml-10 mr-0`};
      }

      .feature_image {
        width: 25%;
        flex-shrink: 0
      }
    }

    .feature-alternate {
      ${tw`flex-row-reverse bg-gray-50`};

      .feature_content {
        ${tw`ml-0 mr-10`};
      }
    }
  }

`;

export default StyledCMSPage;
