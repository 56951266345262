import tw, { styled } from 'twin.macro';

const StyledAuth = styled.article`
  ${tw`mx-auto mt-10 px-2 md:px-16`};

  h1 {
    ${tw`text-left text-xl md:text-2xl`};
  }

  .contentLayout {
    ${tw`w-full mt-16 flex flex-row flex-wrap justify-center`}
  }

  .content {
    ${tw`basis-1/2 mb-16 md:(pr-10) lg:(mb-0)`};
  }

  .authForm {
    ${tw`w-full h-auto basis-1/2 flex flex-row flex-wrap shadow min-w-[350px] lg:(min-w-0)`}
    
    > div {
      ${tw`p-5`};
    }

    .btn {
      ${tw`mx-auto`}
    }
    
    .detail {
      ${tw`w-full basis-2/3 [min-width: 32rem]`};
      
      h3 {
        ${tw`text-left`};
      }
    }

  }
`;

export default StyledAuth;
