import React from 'react';
import { Main } from '@layout';
import { Seo } from '@common';
import { graphql } from 'gatsby';
import { StyledBlogPost } from '@blog/styles';
import { StyledCMSPage } from '@cms_pages/styles';
import { StyledAuth } from '@app_pages/styles';
import rehypeReact from 'rehype-react';
import remarkRehype from 'remark-rehype'
import { StaticImage } from 'gatsby-plugin-image';
import queryString from "query-string";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, id, htmlAst } = markdownRemark;

  const concatQSParams = (iframe_url) => {
    try {

      if (typeof window === 'undefined' || !window.sessionStorage) {
        throw "Window not initiatied";
      }
      const iframe_url_without_qs = iframe_url.split(/[?#]/)[0];
      let pricingOptions = window.sessionStorage.getItem("pricingOptions");
      if (!pricingOptions) throw "No pricing options in session";
      if (typeof pricingOptions === 'string') pricingOptions = JSON.parse(pricingOptions);
  
      return `${iframe_url_without_qs}?${queryString.stringify(pricingOptions)}`;

    } catch (error) {
      return iframe_url;
    }
  };

  const renderAst = new rehypeReact({
    createElement: React.createElement,
  }).Compiler;

  const renderMd = new remarkRehype({
    createElement: React.createElement,
  }).Compiler;

  const renderFeatures = (features) => {
    const featuresMap = features.map((feature, index) => (
      <div className={`feature ${index % 2 === 1 ? 'feature-alternate' : ''}`}>
        <div className="feature_image">
          <img src={feature.feature_image} alt="feature image" className="" />
        </div>
        <div className="feature_content">
          <h3>{feature.feature_title}</h3>
          {feature.feature_body}
        </div>
      </div>
    ));

    return featuresMap;
  };

  return (
    <Main>
      <Seo description="Home page for a Gatsby Starter, featuring Emotion and Tailwind css" />

      {frontmatter.collection === 'blog' && (
        <StyledBlogPost>
          <div className="blog-post contentContainer">
            <h1>{frontmatter.title}</h1>
            <h2>{frontmatter.date}</h2>
            <div>{renderAst(htmlAst)}</div>
          </div>
        </StyledBlogPost>
      )}

      {frontmatter.collection === 'cms_page' && (
        <StyledCMSPage>
          <div className="cms-page contentContainer">
            <h1>{frontmatter.title}</h1>
            <div>{renderAst(htmlAst)}</div>
            {frontmatter.features ? (
              <div className="features">
                {renderFeatures(frontmatter.features)}
              </div>
            ) : (
              <></>
            )}
          </div>
        </StyledCMSPage>
      )}

      {frontmatter.collection === 'auth_page' && (
        <StyledAuth>
          <div className="contentContainer">
            <div className="contentLayout">
              <div className="content">
                <h1>{frontmatter.title}</h1>
                {renderAst(htmlAst)}
              </div>

              <div className="authForm">
                <iframe
                  width="100%"
                  height="415"
                  src={concatQSParams(frontmatter.iframe_url)}
                  allowtransparency="true"
                  frameBorder="0"
                  scrolling="no"
                ></iframe>
              </div>
            </div>
          </div>
        </StyledAuth>
      )}
    </Main>
  );
}
export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      id
      frontmatter {
        collection
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        features {
          feature_title
          feature_image
          feature_body
        }
        iframe_url
      }
    }
  }
`;
